import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { Link } from "gatsby"
import { color } from "./colors"
import Button from "../atomic/atoms/button"

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 15px;
  border-width: 5px 0 2px;
  border-color: ${color.blueLight};
  border-style: solid;

  h2 {
    margin-bottom: 20px;
  }

  span {
    display: block;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    margin-bottom: 32px;
  }

  .link-more {
    display: none;
  }
`

const ArticleBox = ({ article, url }) => {
  return (
    <Box>
      <h2>{parse(article.title)}</h2>
      <span>{parse(article.date)}</span>
      {parse(article.excerpt)}
      <Button
        content={{
          text: "Czytaj więcej...",
          url: `/${url}/${article.slug}`,
        }}
        margin="0 auto 0 0"
      />
    </Box>
  )
}

export default ArticleBox
